
    .story{
        display: flex;
        padding: 0 110px;
        background: #ffffff;
        box-sizing: border-box;
        height: 610px;

        @include sm{
            display: flex;
            flex-direction: column;
            padding: 40px 20px 40px 40px;
            background: #ffffff;
            box-sizing: border-box;
            height: unset;
        }

        @include lg{
            height: unset;
            padding: 55px 25px;
            flex-direction: column;
        }

        @include xl{
            padding: 110px 25px;
            height: unset;

        }

        @include xxl{
            padding: 110px 90px;
        }

        .story-title{
            padding-right: 30px;
            align-self: center;
            position: relative;
            height: 160px;
            border-right: 3px solid #e5e5e5;
            width: 15%;

            @include sm{
                align-self: unset;
                position: relative;
                height: unset;
                margin-bottom: 45px;
                width: 15%;
                border-right: unset;
                position: relative;

                br{
                    display: none;
                }
                

                &::after{
                    position: absolute;
                    content: "";
                    bottom: -19px;
                    left: 0;
                    width: 90px;
                    height: 3px;
                    background-color: #e5e5e5;
                }
            }

            @include lg{
                align-self: unset;
                position: relative;
                height: 140px;
                width: 15%;
                border-right: unset;
                position: relative;

                &::after{
                    position: absolute;
                    content: "";
                    bottom: 10px;
                    left: 0;
                    width: 90px;
                    height: 3px;
                    background-color: #e5e5e5;
                }

            }

            span:nth-child(1){
                font-size: 48px;
                font-style: normal;
                color: rgba(179, 180, 180, 1);
                font-weight: 800;
                line-height: 59px;
                letter-spacing: 1.5px;
                text-align: left;

                @include sm{
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 1px;
                    text-align: left;
                    margin-right: 6px;

                }

                @include lg{
                    font-size: 38px;
                }

                @include xl{
                    font-size: 34px;
                }

                @include xxl{
                    font-size: 44px;
                }
                
            }

            span:last-child{
                color: rgba(0, 126, 0, 1);
                font-size: 48px;
                font-weight: 700;
                letter-spacing: 1.5px;

                @include sm{
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 1px;
                    text-align: left;
                }

                @include lg{
                    font-size: 38px;
                }
                
                @include xl{
                    font-size: 34px;
                }

                @include xxl{
                    font-size: 44px;
                }
            }
        }

        .story-slider{
            margin-left: 50px;
            display: flex;
            align-items: center;
            gap: 35px;

            @include sm{
                margin-left: unset;
                align-items: center;
                gap: 20px;
                flex-direction: column;
            }

            @include lg{
                gap: 30px;
                margin-left: unset;
            }

            @include xl{
                gap: 25px;
                margin-left: 25px;
            }

            .story-card{
                width: 270px;
                min-height: 400px;
                

                @include sm{
                    width: unset;
                    min-height: unset;
                }

                @include xl{
                    width: 250px;

                }
                

                .story-card-info.active{

                    font-size: 14px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: 0.5px;
                    color: rgba(0, 0, 0, 0.74);
                    

                    // &::first-letter{
                    //     font-size: 48px;
                    //     letter-spacing: 1.5px;
                    //     font-weight: 800;
                    //     color: rgba(0, 126, 0, 1);
                    // }
                }


                .story-card-info{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    color:rgba(179, 180, 180, 1);
                    

                    &:hover{
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 28px;
                        letter-spacing: 0.5px;
                        color: rgba(0, 0, 0, 0.74);
                    }

                    .capital.active{
                        color: rgba(0, 126, 0, 1);
                    }

                    .capital{
                        font-size: 48px;
                        letter-spacing: 1.5px;
                        font-weight: 800;
                        color: rgba(0, 126, 0, 0.4);
                    }
                }
            }
        }
    }