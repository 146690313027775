@import '../abstract/breakpoint.scss' ;

.navbar.open{
    background: #fff;
    padding-bottom: 6px;
}

.navbar{
    position: fixed;
    z-index: 5;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    padding: 0 110px;

    @include sm{
        height: 55px;
        background: #fff;
        padding: 0;
    }

    @include lg{
        min-height: 50px;
        padding: 0 20px;
    }

    @include xl{
        padding: 0 40px;
    }

    @include xxl{
        padding: 0 90px;
    }



    .navbar-wrap{
        display: flex;
        justify-content: space-between;


        @include sm{
            background: #fff;
            width: 100%;
            position: relative;
            box-sizing: border-box;
            align-items: center;
            transform: translateY(10px);
            padding: 0 25px;
        }
        
            // padding: 20px 20px;

            .wrap-logo{
                background: #fff;
                flex-direction: column;
                width: 260px;

                @include sm{
                    padding: 0;
                }
              

                @include xl{
                    width: 245px;
                }

                img{
                    width: 100%;
                    height: 40px;
                    transform: translateY(10px);

                    @include sm{
                        width: 190px;
                        height: 30px;
                        transform: translateY(5px);
                    }

                    @include lg{
                        width: 220px;
                        height: 37px;
                        transform: translateY(10px);
                    }
                }
            }

            .link-wrap.open{
                left: 0;
                transform: translateY(40px);

            }


        .link-wrap{
            display: flex;
            align-items: center;

            @include sm{
                left: -450px;
                position: absolute;
                flex-direction: column;
                align-items: flex-start;
                transform: translatey(55px);
                position: absolute;
                padding: 15px 0 20px 20px;
                background: #fff;
                width: 100%;
                box-sizing: border-box;
                top: 0;
                
            }

            .link.active{
                color: rgba(0, 126, 0, 1);
                position: relative;

                @include sm{

                    &::after{
                        display: none;
                    }
                }

                &::after{
                    position: absolute;
                    width: 30px;
                    content: "";
                    height: 3px;
                    background-color: #007e00;
                    bottom: -8px;
                    left: 50%;
                    transform: translate(-50%);
                    border-radius: 5px;
                }

            }

            .link{
                margin-left: 60px;
                text-decoration: none;
                color: rgba(185, 185, 185, 1);
                font-size: 17px;
                letter-spacing: 0.5px;
                font-weight: 600;
                cursor: pointer;

                @include sm{
                    font-size: 16px;
                    margin-left: 0;
                    letter-spacing: 0.5px;
                    font-weight: 600;
                    cursor: pointer;
                    margin-bottom: 12px;
                }

                @include lg{
                    font-size: 15px;
                    margin-left: 20px;
                }

                @include xl{
                    font-size: 16px;
                    margin-left: 40px;
                } 
            }
        }

        .hamburger-menu{
            display: none;
            width: 30px;
            height: 30px;
            position: relative;
            align-items: center;
            transition: all 1s;

            @include sm{
                display: flex;
            }

            .btn-hamburger{
                display: none;
                width: 18px;
                height: 3px;
                background-color: #007e00;
                border-radius: 4px;
                position: relative;
                transition: all 1s;

                @include sm{
                    display: block;
                }

                &::after , &::before{
                    position: absolute;
                    content: "";
                    width: 18px;
                    height: 3px;
                    background-color: #007e00;
                    border-radius: 4px;
                }

                &::after {
                    transform: translateY(-6px);
                }

                &::before {
                    transform: translateY(6px);
                }
            }
        }

        .hamburger-menu.open .btn-hamburger{
            transition: left 1s;
            left: -20px;
            background-color: transparent;

            &::before{
                transform: translate(20px , 0px) rotate(45deg);
            }

            &::after{
                transform: translate( 20px , 0px) rotate(-45deg);
            }
        }
    }
}