@import '../abstract/breakpoint.scss' ;

.home{

    &::-webkit-scrollbar{
        display: none;
    }

    .header-home{
        position: relative;
        padding: 0 110px;
        height: 710px;
        background-image: url('../../assets/images/header-bg.png');
        background-size: 100%;

        @include sm{
            background-image: url('../../assets/images/bg-mobile.png');
            padding: 0;
            height: 550px;
            background-size: cover;
            background-position: center;
        }

        @include lg{
            background-repeat: no-repeat;
            background-size: cover;
            height: 500px;
            padding: 0 25px;
        }

        @include xl{
            background-repeat: no-repeat;
            background-size: cover;
            height: 600px;
            padding: 0 40px;
        }

        @include xxl{
            padding: 0 90px;
            background-size: cover;
        }

        .header-content{
            background-color: #fff;
            position: absolute;
            width: 510px;
            left: 0;
            height: 100%;
            top: 0;
            display: flex;
            flex-wrap: nowrap;
            /* align-items: center; */
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            padding: 0 90px 0 110px;

            @include sm{        
                height: 85%;
                width: 100%;
                padding: 0px 40px;
                background-color: unset;
            }

            @include lg{
                width: 350px;
                padding: 0 25px 30px 25px;
                justify-content: flex-end;
            }

            @include xxl{
                padding: 0 90px;
            }

            .header-content-title{
                width: 690px;
                font-size: 55px;
                font-weight: 700;
                line-height: 75px;
                letter-spacing: 5px;
                margin-top: 0;
                color: rgba(0, 126, 0, 1);
                position: relative;

                @include sm{
                    font-family: Montserrat;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 1.5px;
                    text-align: left;
                    width: unset;

                    &::after{
                        bottom: -28px  !important;
                        width: 90px !important;
                        height: 5px !important;
                    }
                }

                @include lg{
                    width: 526px;
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 42px;
                }

                @include xl{
                    font-size: 40px;
                    line-height: 60px;
                }

                @include xxl{
                    font-size: 50px;
                    line-height: 75px;
                }
                
                &::after{
                    position: absolute;
                    content: "";
                    bottom: -50px;
                    background-color: #e5e5e5;
                    border-radius: 10px;
                    width: 150px;
                    left: 0;
                    height: 10px;
                }
                
            }

            .header-content-info{
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.5px;
                font-weight: 500;
                color: rgb(25,25,25);
                margin-top: 60px;

                @include sm{
                    font-size: 12px;
                    margin-top: 40px;
                    letter-spacing: 1px;
                }

                @include lg{
                    font-size: 14px;
                    line-height: 25px;
                }
            }
        }
    }

    .mission{
        display: flex;
        padding: 0 110px;
        background: rgba(248, 248, 248, 1);
        box-sizing: border-box;
        height: 610px;

        @include sm{
            padding: 50px 10px 40px 40px;
            background: #f8f8f8;
            flex-direction: column;

        }

        @include lg{
            padding: 55px 25px;
            flex-direction: column;
             height: unset;
        }

        @include xl{
            padding: 0 25px;

        }

        @include xxl{
            padding: 0 90px;
        }


        .mission-title{
            padding-right: 30px;
            align-self: center;
            position: relative;
            height: 160px;
            width: 15%;
            border-right: 3px solid #e5e5e5;

            @include sm{
                align-self: unset; 
                position: relative;
                height: unset; 
                margin-bottom: 45px;
                width: 15%;
                border-right: unset;
                position: relative;

                br{
                    display: none;
                }

                &::after{
                    position: absolute;
                    width: 70px;
                    height: 3px;
                    content: "";
                    background-color: #e5e5e5;
                    bottom: -15px;

                }

            }


            @include lg{
                align-self: unset;
                border-right: unset;
                height: 150px;
                position: relative;

                &::after{
                    position: absolute;
                    content: "";
                    width: 90px;
                    height: 3px;
                    background-color: #e5e5e5;
                    bottom: 15px;
                    left: 0;
                }
            }

            

            span:nth-child(1){
                font-size: 48px;
                font-style: normal;
                color: rgba(179, 180, 180, 1);
                font-weight: 800;
                line-height: 59px;
                letter-spacing: 1.5px;
                text-align: left;

                @include sm{
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 1px;
                    text-align: left;
                    margin-right: 6px;

                }

                @include lg{
                    font-size: 38px;
                }

                @include xl{
                    font-size: 34px;
                }

                @include xxl{
                    font-size: 44px;
                }
            }

            span:last-child{
                color: rgba(0, 126, 0, 1);
                font-size: 48px;
                font-weight: 700;
                letter-spacing: 1.5px;

                @include sm{
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 1px;
                    text-align: left;
                }

                @include lg{
                    font-size: 38px;
                }

                @include xl{
                    font-size: 34px;
                }

                @include xxl{
                    font-size: 44px;
                }
            }
        }

        .mission-wrap{
            // margin-left: 50px;
            display: flex;
            align-items: center;
            gap: 35px;
            /* width: 85%; */
            overflow-x: scroll;

            &::-webkit-scrollbar{
                display: none;
            }

            @include sm{
                margin-left: 0;
            }

            @include lg{
                margin-left: unset;
                width: unset;
                
            }

            @include xl{
                margin-left: 0;
            }


            .mission-slider{
                margin-left: 50px;
                display: flex;
                align-items: center;
                gap: 35px;

                &::-webkit-scrollbar{
                    display: none;
                }
    
                @include sm{
                    margin-left: unset;
                    overflow: scroll;
                }

                @include lg{
                    margin-left: unset;
                }
    
    
                .mission-card{
                        height: 400px;
                        min-width: 300px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 25px;
    
                        @include sm{
                            height: 300px;
                            min-width: 210px;
                            position: relative;
                            overflow: hidden;
                            border-radius: 25px;
    
                            img{
                                position: absolute;
                                width: 100%;
                                z-index: 0;
                                height: 100%;
                            }
                            
                        }
    
                    img{
                        position: absolute;
                        width: 100%; 
                        z-index: 0;
                    }
    
                    .wrap-info{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        top: 0;
                        background-color: rgba(35, 220, 124, 0.726);
    
                        .info{
                               font-size: 17px;
                                line-height: 24px;
                                color: #fff;
                                padding: 0 25px;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: 0.5px;
    
                                @include sm{
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 22px;
                                    letter-spacing: 0.5px;
                                    text-align: center;
                                    padding: 0 15px;
                                }
                        }
                    }
                }
            }
        }
    }

    .team{
        background: rgba(0, 126, 0, 1);
        padding: 110px 110px;
        display: flex;
        box-sizing: border-box;

        @include sm{
            padding: 30px 25px 50px;
            flex-direction: column;
        }

        @include lg{
            padding: 72px 20px;
        }

        @include xl{
            padding: 110px 25px;
        }

        @include xxl{
            padding: 110px 90px;
        }
        

        .team-title{
            font-size: 48px;
            font-style: normal;
            font-weight: 800;
            line-height: 59px;
            letter-spacing: 1.5px;
            text-align: left;
            padding-right: 30px;
            margin: 0;
            color: #fff;
            width: 15%;
            border-right: 3px solid #e5e5e5;
            height: 150px;
            position: relative;

            @include sm{
                font-size: 24px;
                border-right: unset;
                width: unset;
                height: unset;
                margin: 0 0 45px;
                position: relative;

                br{
                    display: none;
                }

                &::after{
                    position: absolute;
                    content: "";
                    width: 90px;
                    height: 3px;
                    background-color: #e5e5e5;
                    bottom: 0 ;
                    left: 0;
                }
            }

            @include lg{
                font-size: 32px;
                line-height: 40px;
                height: 100px;
            }

            @include xl{
                font-size: 34px;
                line-height: 48px;
                height: 130px;
                padding-right: 0;
            }

            @include xxl{
                font-size: 44px;
            }
        }

        .team-wrap{
            display: flex;
            width: 85%;
            justify-content: center;

            @include sm{
                width: unset;

                img{
                    width: 80%;
                }
            }

            @include lg{
                justify-content: flex-start;

                img{
                    width: 390px;
                    height: 450px;
                }
            }
        }
    }

    .join{
        padding: 0 110px;
        height: 610px;
        display: flex;
        align-items: center;
        background: rgba(248, 248, 248, 1);

        @include sm{
            padding: 0px 20px;
            height: 810px;

        }

        @include lg{
            padding: 30px 25px 90px;
            height: unset;
            box-sizing: border-box;
        }

        @include xl{
            padding: 0 20px;
        }


        .join-wrap{
            display: flex;

            @include sm{
                flex-direction: column;
                width: 100%;
            }

            @include lg{
                width: 100%;
            }

            .img-wrap{
                width: 500px;

                @include sm{
                    width: 100%;
                }

                @include lg{
                    width: 280px;
                }
    
                img{
                    width: 100%;

                    @include xl{
                        width: 400px;
                    }

                    @include lg{
                        transform: translateY(20%);
                    }
                }
            }
    
            .content{
                margin-left: 100px;
                padding-top: 30px;

                @include sm{
                    margin-left: 0px;
                }

                @include lg{
                    margin-left: 40px;
                }
    
                .content-title{
                    width: 538px;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 59px;
                    letter-spacing: 1.5px;
                    text-align: left;
                    color: rgba(0, 126, 0, 1);
                    position: relative;

                    @include sm{
                        line-height: 40px;
                        width: unset;
                        font-size: 28px;

                    }

                    @include lg{
                        width: 450px;
                        font-size: 38px;
                        line-height: 50px;
                    }

                    @include xl{
                        width: unset;
                        font-size: 34px;
                        line-height: 45px;
                    }

                    &::after{
                        position: absolute;
                        content: "";
                        width: 150px;
                        background-color: #e5e5e5;
                        height: 7px;
                        border-radius: 10px;
                        bottom: -30px;
                        left: 0;
                    }
                }
    
                a{
                    width: 210px;
                    display: block;
                    text-decoration: none;
                    height: 20px;
                    border: 3px solid rgba(0, 126, 0, 1);
                    text-align: center;
                    font-size: 20px;
                    padding: 12px 0;
                    color: rgba(0, 126, 0, 1);
                    font-weight: 700;
                    border-radius: 40px;
                    transform: translateY(70px);
                    cursor: pointer;

                    @include sm{
                        transform: translateY(30px);
                        width: 180px;
                        font-size: 18px;
                    }

                    @include lg{
                        transform: translateY(40px);
                    }

                    @include xl{
                        transform: translateY(40px);
                    }
                }
            }
        }
    }
}