.collab{
    padding: 90px 110px;
    display: flex;

    @include sm{
        padding: 40px 40px 40px 40px;
        display: flex;
        flex-direction: column;

    }

    @include lg{
        padding: 90px 25px;
        flex-direction: column;
    }

    @include xl{
        padding: 90px 25px;
    }

    @include xxl{
        padding: 90px;
    }

    .collab-title{
        padding-right: 30px;
        position: relative;
        height: 160px;
        width: 15%;
        border-right: 3px solid #e5e5e5;
        transform: translateY(60px);

        @include sm{
            display: none;
        }

        @include lg{
            display: none;
        }

        span:nth-child(1){
            font-size: 48px;
            font-style: normal;
            color: #b3b4b4;
            font-weight: 800;
            line-height: 59px;
            letter-spacing: 1.5px;
            text-align: left;

            @include xl{
                font-size: 34px;
                line-height: 45px;
            }

            @include xxl{
                font-size: 44px;
            }
        }

        span:last-child{
            font-size: 48px;
            font-style: normal;
            color: #b3b4b4;
            font-weight: 800;
            line-height: 59px;
            color: rgba(0, 126, 0, 1);
            letter-spacing: 1.5px;
            text-align: left;
        
            @include xl{
                font-size: 34px;
                line-height: 45px;
            }

            @include xxl{
                font-size: 44px;
            }
        }
    }

    .collab-title-mobile{
        display: none;
        position: relative;

        @include sm{
            display: block;

            &::after{
                position: absolute;
                content: "";
                height: 3px;
                width: 70px;
                background-color: #b3b4b4;
                bottom: -5px;
                left: 0;
            }
        }

        @include lg{
            display: block;

            &::after{
                position: absolute;
                content: "";
                height: 3px;
                width: 90px;
                background-color: #b3b4b4;
                bottom: -15px;
                left: 0;
            }
        }

        span:nth-child(1){
            font-size: 24px;
            font-style: normal;
            color: #b3b4b4;
            font-weight: 800;
            letter-spacing: 1px;
            text-align: left;

            @include lg{
                font-size: 38px;
            }
        }

        span:last-child{
            font-size: 24px;
            font-style: normal;
            color: #b3b4b4;
            font-weight: 800;
            line-height: 59px;
            color: rgba(0, 126, 0, 1);
            letter-spacing: 1.5px;
            text-align: left;

            @include lg{
                font-size: 38px;
            }
        }
    }

    .wrap-collab{
        margin-left: 50px;
        display: flex;
        flex-direction: column;

        @include sm{
            margin-left: unset; 
            display: flex;
            flex-direction: column;
            padding-top: 50px;
        }

        @include lg{
            margin-left: unset;
            flex-direction: column;
            margin-top: 45px;
        }

        @include xl{
            margin-left: 25px;
        }

        .wrap-card{
            display: flex;
            height: 410px;
            background: rgba(248, 248, 248, 1);
            /* border: 3px solid rgba(248, 248, 248, 1); */
            width: 930px;
            border-radius: 25px;
            margin-bottom: 30px;

            @include sm{
                flex-direction: column;
                height: unset; 
                background: #f8f8f8;
                border:unset;
                width: unset; 
                border-radius: 25px;
                margin-bottom: 30px;
            }

            @include lg{
                background: #f8f8f8;
                border: unset ; 
                width: unset; 
                border-radius: 25px;
                margin-bottom: 30px;
            }

            @include xl{
                width: 795px;
                height: 420px;
            }


            .card-img-f{
                width: 300px;

                @include sm{
                    width: 100%;
                    height: 200px;
                }

                img{
                    border-radius: 25px 0 0 25px;

                    @include sm{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 25px 25px 0 0;
                    }
                }
            }

            .card-img{
                display: flex;
                align-items: center;
                min-width: 300px;
                justify-content: center;
                border-radius: 25px;
            }

            .card-info{
                box-sizing: border-box;
                padding: 30px 50px;

                @include sm{
                    padding: 0 20px 20px;
                }

                @include lg{
                    padding: 15px 20px;
                }

                @include xl{
                    padding: 0px 30px 30px;
                }

                .title-info{
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 37px;
                    color: rgba(0, 126, 0, 1);
                    letter-spacing: 1.5px;
                    text-align: left;

                    @include sm{
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        text-align: left;
                    }

                    @include lg{
                        font-size: 26px;
                        margin: 6px 0;
                    }

                    @include xl{
                        font-size: 26px;
                        line-height: 37px;
                    }
                }

                .info.open{
                    height: unset;
                    display: block !important;
                    -webkit-line-clamp: unset; /* number of lines to show */
                                line-clamp: 2; 
                        -webkit-box-orient: unset;
                }

                .info{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    color: rgba(128, 128, 128, 1);
                    text-align: left;

                    @include sm{
                        word-wrap: break-word;
                        overflow: hidden;
                        margin-bottom: 4px;
                        font-size: 12px;
                        text-overflow: ellipsis;
                        position: relative;
                        display: -webkit-box;
                        -webkit-line-clamp: 6; /* number of lines to show */
                                line-clamp: 2; 
                        -webkit-box-orient: vertical;
                    }

                    @include lg{
                        font-size: 13px;
                    }
                }

                .more{
                    display: none;
                    background: transparent;
                    border: none;
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                    color: #007e00;
                    font-weight: 600;
                    letter-spacing: 0.5px;

                    @include sm{
                        display: block;
                    }
                }

                .btn-pertahara{
                    height: 42px;
                    width: 210px;
                    left: 350px;
                    font-size: 16px;
                    text-decoration: none;
                    top: 293px;
                    display: flex;
                    border-radius: 40px;
                    background: rgba(0, 182, 11, 1);
                    /* padding: 19px 59px 19px 59px; */
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    margin-top: 25px;
                    color: #fff;
                }
            }
        }
    }
}