@import '../abstract/breakpoint.scss' ;

.footer{
    padding: 0 110px;
    height: 410px;
    box-sizing: border-box;
    padding-top: 70px;
    background : rgba(0, 126, 0, 1);

    @include sm{
        padding: 0 15px 20px;
        height: unset; 
        padding-top: 45px;
    }

    @include lg{
        padding: 0 25px 20px;
        height: 345px;
        box-sizing: border-box;
        padding-top: 40px;
    }

    @include xl{
        padding: 45px 20px;
    }

    @include xxl{
        padding:  40px 90px ;
    }
    

    .footer-wrap{
        display: flex;
        justify-content: space-between;  
        color: #fff;    
        height: 85%;
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.466);  

        @include sm{
            flex-direction: column;
        }

        @include lg{
            gap: 40px;
        }

        .footer-img{
            display: flex;
            align-items: center;
            height: 30px;
            
            @include sm{
                img{
                    height: 28px;
                }

                p{
                    font-size: 15px !important;
                }
            }

            @include lg{

                img{
                    height: 30px;
                }

                p{
                    font-size: 16px !important;
                }
            }

            p{
                display: inline;
                margin: 0;
                font-family: Montserrat;
                font-size: 23px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.2em;
                text-align: left;
                margin-left: 8px;
            }
        }

        .wrapper-links{
            display: flex;

            @include sm{
                flex-direction: column;
                padding-bottom: 20px;
            }
    
            .menu{
                width: 200px;
                display: flex;
                flex-direction: column;
                margin-right: 100px;

                @include sm{
                    flex-direction: column;
                    margin-right: unset;
                }

                @include lg{
                    margin-right: 30px;
                    width: 140px;
                }

                @include xl{
                    margin-right: 50px;
                }
    
                h2{
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-align: left;

                    @include sm{
                        font-size: 18px;
                    }

                    @include lg{
                        margin: 0 0 15px;
                    }
                }
    
                a{
                    margin-bottom: 10px;
                    color: #fff;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
    
            .contact{
                width: 300px;

                @include lg{
                    width: 285px;
                }
    
                h2{
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-align: left;
                    color: 0;

                    @include sm{
                        font-size: 18px;
                    }

                    @include lg{
                        margin: 0 0 15px;
                    }
                }
    
                .wrap-contact{
                    display: flex;
                    gap: 30px;
                    

                    .contact-info{

                        img{
                            margin-right: 10px;
                        }

                        p{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .copy{
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #fff;

        @include sm{
            font-size: 12px;
        }
    }
}